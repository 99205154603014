<template>
  <div>
    <div class="bjffff"></div>
    <div class="content">
      <div class="contentBox">
        <div class="serchBox">
          <div class="logoImg">
            <img src="../../assets/img/logo-p.png" alt="" srcset="" />
          </div>
          <div class="inpBox">
            <div class="inp">
              <div class="search">
                <div class="iconfont icon-search"></div>
              </div>
              <div class="shu"></div>
              <input
                type="text"
                placeholder="请输入机器名称"
                v-model="seachName"
                name=""
                id=""
                @keyup.enter="searchClick"
              />
              <div class="searchBtn" @click="searchClick">搜索</div>
            </div>
            <div class="textBox">
              <div
                class="txt"
                v-for="item in keywords"
                @click="keywordsClick(item)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="contBox">
          <div
            class="tcBox"
            @mouseenter="mouseover()"
            @mouseleave="mouseout"
            v-if="jxShow"
          >
            <div class="list scroll">
              <div class="name">{{ jxName }}</div>
              <div
                class="li"
                v-for="item in modelList"
                @click="keywordsClick(item)"
              >
                {{ item.name }}
              </div>
            </div>
            <div class="tjCpBox">
              <div class="name">猜你喜欢</div>
              <div class="tjList">
                <div class="tjLi" v-for="item in modelData">
                  <div class="img">
                    <img :src="$imgUrl + item.resources[0].path_name" alt="" />
                  </div>
                  <div class="text">{{ item.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="leftBox">
            <div class="title">机型分类</div>
            <div class="list">
              <div
                class="li"
                v-for="item in series"
                @mouseenter="mouseover(item)"
                @mouseleave="mouseout"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="lbBox">
            <a-carousel autoplay>
              <div class="imgs" v-for="item in rotation">
                <img :src="$imgUrl + item.resource" alt="" srcset="" />
              </div>
            </a-carousel>
          </div>
          <div class="funBox">
            <div class="cont">
              <div class="titleBox" @click="newsJump">
                <div class="name">新闻列表</div>
                <div class="iconfont icon-enter enter"></div>
              </div>
              <div class="list">
                <div
                  class="li c-1"
                  v-for="item in news"
                  @click="ltjump(item.id)"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
            <div class="cont mb0">
              <div class="titleBox" @click="hotClick">
                <div class="name">热门产品</div>
                <div class="iconfont icon-enter enter"></div>
              </div>
              <div class="cpImgBox">
                <div
                  class="cpImg"
                  v-for="item in hotparts"
                  @click="cpJump(item.id)"
                >
                  <img :src="$imgUrl + item.resources[0].path_name" alt="" />
                  <div class="box">
                    <div class="txt c-1">{{ item.name }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="userBox">
            <div class="logCont">
              <div class="txImg">
                <img v-if="user" :src="$imgUrl + user.avatar" alt="" />
                <img v-else src="../../assets/img/logo.png" alt="" />
              </div>
              <div class="timeTxt">{{ user ? user.name : timeTxt }}</div>
              <div class="dlBox" v-if="!user">
                <div class="box" @click="dlClick">登录</div>
                <div class="box" @click="zcshow = true">注册</div>
              </div>
              <div class="jlBox" v-if="user">
                <a-carousel
                  autoplay
                  :after-change="onChange"
                  :dots="false"
                  v-if="readlist"
                >
                  <div class="jlBoxCont" v-for="item in readlist">
                    <div class="img" v-if="item.part.resources">
                      <img
                        :src="$imgUrl + item.part.resources[0].path_name"
                        alt=""
                      />
                    </div>
                    <div class="box">
                      <div class="name">{{ item.part.name }}</div>
                      <div class="txt">{{ item.part.modelname }}</div>
                    </div>
                    <div class="btn" @click="cpJump(item.part.id)">查看</div>
                  </div>
                </a-carousel>
                <div
                  v-if="!readlist"
                  class="jlBoxCont dis-cent"
                  style="color: #888"
                >
                  快去浏览产品吧~~
                </div>
              </div>
              <div class="cxBox">
                <div class="box" v-for="item in adver">
                  <div class="cxImg">
                    <img :src="$imgUrl + item.resource" alt="" />
                  </div>
                  <div class="txt c-1" :title="item.name">{{ item.name }}</div>
                </div>
                <!-- <div
                  class="box"
                  v-for="(item, index) in cxList"
                  v-on:mouseover="mouseCxover(index)"
                  v-on:mouseout="mouseCxout(index)"
                >
                  <div class="ewmBox" v-if="item.select">
                    <div class="icon">
                      <div class="iconfont icon-play_fill play_fill"></div>
                    </div>
                    <div class="img">
                      <img
                        src="https://img1.baidu.com/it/u=3685234108,954911792&fm=253&fmt=auto&app=138&f=GIF?w=200&h=200"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="cxImg">
                    <img
                      src="https://img0.baidu.com/it/u=2528037528,3946444635&fm=253&app=120&size=w931&n=0&f=JPEG&fmt=auto?sec=1721235600&t=f6393d8b79c2071a76dda241d9e93416"
                      alt=""
                    />
                  </div>
                  <div class="txt">川田公众号</div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="contentTitle">
          <div class="hen"></div>
          <div class="name">产品推荐</div>
          <div class="hen1"></div>
        </div> -->
        <!-- <div class="typeList">
          <div class="type on">全部类型</div>
        </div> -->
        <div class="selectBox">
          <div class="titleBox">
            <div class="name">全部产品</div>
            <div class="inpBox">
              <input
                type="text"
                placeholder="请输入产品名称搜索"
                v-model="cpName"
                @keyup.enter="cpSearchClick"
              />
              <div
                class="iconfont icon-search search"
                @click="cpSearchClick"
              ></div>
            </div>
          </div>
          <div class="pzBox pzBoxh">
            <div class="pzCnt">
              <!-- <div class="name">规格:</div> -->
              <div class="boxs">
                <div
                  class="box"
                  v-for="item in typeList"
                  :class="item.id == typeOn ? 'on' : ''"
                  @click="typeClick(item.id)"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="contList">
          <div class="cont" v-for="item in list" @click="cpJump(item.id)">
            <div class="cpImg">
              <img :src="$imgUrl + item.resources[0].path_name" alt="" />
            </div>
            <div class="title c-1">{{ item.name }}</div>
            <div class="money">
              <div class="txt">￥</div>
              <div class="num">{{ item.minprice }}</div>
              <div class="text">{{ item.num }}+人购买</div>
            </div>
          </div>
          <!-- <div class="cont">
            <div class="bjImg">
              <img src="../../assets/img/bj1.png" alt="" />
              <div class="bjBox">
                <div class="contTxt">
                  <div class="title1">精选推荐</div>
                </div>
                <div class="bjList">
                  <div class="bjLi">推荐1</div>
                  <div class="bjLi">推荐1</div>
                  <div class="bjLi">推荐1</div>
                  <div class="bjLi">推荐1</div>
                  <div class="bjLi">推荐1</div>
                  <div class="bjLi">推荐1</div>
                  <div class="bjLi">推荐1</div>
                  <div class="bjLi">推荐1</div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <Kong v-if="list.length == 0" />
        <div class="fenye" v-if="totalCount > 20">
          <a-pagination
            :default-current="page.pageNo"
                  v-model="page.pageNo"
            :total="totalCount"
            :page-size="20"
            @change="pageOnChange"
          />
        </div>
      </div>
    </div>
    <FunBox />
    <Zhuce v-if="zcshow" @show="zhuceShowJs" />
  </div>
</template>

<script>
import Zhuce from "../component/zhuce.vue";
import FunBox from "../component/funBox.vue";
import Kong from "../component/kong.vue";
export default {
  name: "",
  components: { FunBox, Zhuce, Kong },
  data() {
    return {
      zcshow: false,
      jxShow: false,
      cxList: [
        {
          select: false,
        },
        {
          select: false,
        },
        {
          select: false,
        },
        {
          select: false,
        },
        {
          select: false,
        },
        {
          select: false,
        },
      ],
      hotparts: {},
      keywords: {},
      news: {},
      series: {},
      rotation: {},
      seachName: "",
      modelList: [],
      judge: true,
      page: {
        pageNo: 1,
        pageSize: 20,
      },
      list: [],
      user: null,
      timeTxt: "",
      readlist: [],
      jxName: "",
      modelData: [],
      cpName: "",
      typeOn: "",
      typeList: [],
      totalCount: 0,
      adver: [],
    };
  },
  created() {
    this.get();
    this.listGet();
    this.timeTxt = this.getTimeOfDay();
    this.userGet();
    this.typeGet();
  },
  methods: {
    typeClick(id) {
      this.typeOn = id;
      this.page.config_id = id;
      this.page.pageNo = 1;
      this.totalCount = 0
      this.list = [];
      this.listGet();
    },
    typeGet() {
      this.$get("/common/configuration_gets", {
        type: "part",
      }).then((res) => {
        this.typeList = res.data;
        this.typeList.unshift({
          id: "",
          resources: null,
          name: "全部类型",
        });
      });
    },
    zhuceShowJs() {
      this.zcshow = false;
    },
    dlClick() {
      this.$router.push({
        path: "/login",
        query: {},
      });
    },
    ltjump(id) {
      this.$router.push({
        path: "/gonggao",
        query: {
          id: id,
        },
      });
    },
    getTimeOfDay() {
      const currentHour = this.$moment().hour();
      if (currentHour >= 5 && currentHour < 12) {
        return "早上好";
      } else if (currentHour >= 12 && currentHour < 18) {
        return "下午好";
      } else {
        return "晚上好";
      }
    },
    userGet() {
      this.$get("/web/member", {}, true).then((res) => {
        this.user = res.member;
        this.readlist = res.readlist;
      });
    },
    get() {
      this.$get("/web/index").then((res) => {
        console.log(res);

        // 热门产品
        if (res.hotparts) {
          this.hotparts = res.hotparts;
        }
        // 搜索
        if (res.keywords) {
          this.keywords = res.keywords;
        }
        // 新闻
        if (res.news) {
          this.news = res.news;
        }
        // 机型分类
        if (res.series) {
          this.series = res.series;
        }
        // 轮播
        if (res.rotation) {
          this.rotation = res.rotation;
        }
        // 轮播
        if (res.adver) {
          this.adver = res.adver;
        }
      });
    },
    cpSearchClick() {
      if (!this.cpName) {
        this.$message.warning("搜索内容不能为空");
        return;
      }
      this.page.name = this.cpName;
      this.page.pageNo = 1;
      this.list = [];
      this.totalCount = 0
      this.listGet();
    },
    listGet() {
      if (!this.judge) {
        return;
      }
      this.judge = false;
      this.$get("/web/part_gets", this.page).then((res) => {
        console.log(res);
        this.judge = true;
        if (!this.totalCount) {
          this.totalCount = res.totalCount;
        }
        if (res.data) {
          for (let i = 0; i < res.data.length; i++) {
            const e = res.data[i];
            e.num = this.roundToNearestTen(e.sales);
            this.list.push(e);
          }
          this.page.pageNo++;
        } else {
          if (this.page.pageNo != 1) {
            this.$message.warning("已展示全部内容");
          }
        }
      });
    },
    roundToNearestTen(num) {
      if (num <= 10) {
        return 10;
      } else {
        // 获取数字的整数部分
        let intPart = Math.floor(Math.abs(num));
        // 判断个位数是否大于或等于5，决定是向上还是向下舍入
        if (intPart % 10 >= 5) {
          // 如果大于或等于5，则向上舍入
          intPart += 10 - (intPart % 10);
        } else {
          // 如果小于5，则直接向下舍入整十
          intPart -= intPart % 10;
        }
        // 处理负数和保留原始符号
        return (num < 0 ? -intPart : intPart) * Math.sign(num);
      }
    },
    scrollEvent() {
      let top = document.querySelector("#ysCont").scrollTop;
      let height = document.querySelector("#ysCont").scrollHeight;
      let offHeight = document.querySelector("#ysCont").offsetHeight;
      if (offHeight + top - height >= 0) {
        if (this.list.length < 20) {
          return;
        }
        this.listGet();
        // 需要执行的代码
      }
    },
    // 搜索
    keywordsClick(item) {
      window.open("/chuantian/shopList?name=" + item.name);
    },
    newsJump() {
      this.$router.push({
        path: "/ggList",
        query: {},
      });
    },
    searchClick() {
      window.open("/chuantian/shopList?name=" + this.seachName);
    },
    hotClick() {
      window.open("/chuantian/shopList?type=1");
    },
    mouseover(item) {
      this.jxShow = true;
      if (item) {
        this.jxName = item.name;
        this.$get(
          "/web/model_gets",
          {
            id: item.id,
          },
          true
        ).then((res) => {
          console.log(res);
          this.modelList = res.list;
          this.modelData = res.data;
        });
      }
    },
    mouseout() {
      this.jxShow = false;
    },
    mouseCxover(i) {
      this.cxList[i].select = true;
    },
    mouseCxout(i) {
      this.cxList[i].select = false;
    },
    onChange(a) {
      // console.log(a);
    },
    cpJump(id) {
      this.$router.push({
        path: "/shopCont",
        query: {
          id,
        },
      });
    },
  },
  mounted() {},
  computed: {},
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  .contentBox {
    width: 1440px;
    margin: auto;
    background-color: #f5f4f4;
    box-sizing: border-box;
    padding: 10px 15px;
    .serchBox {
      display: flex;
      padding: 30px 70px;
      align-items: center;
      .logoImg {
        width: 250px;
      }
      .inpBox {
        width: calc(100% - 250px);
        box-sizing: border-box;
        padding-left: 50px;
        .inp {
          border: 2px solid #f66;
          background-color: #fff;
          width: 100%;
          height: 50px;
          border-radius: 10px;
          display: flex;
          overflow: hidden;
          align-items: center;
          position: relative;
          .search {
            width: 65px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            .iconfont {
              font-size: 24px;
              color: #999;
            }
          }
          .shu {
            width: 2px;
            height: 20px;
            background-color: #c9c9c9;
          }
          input {
            width: calc(100% - 67px);
            height: 100%;
            padding-left: 20px;
            box-sizing: border-box;
          }
          .searchBtn {
            cursor: pointer;
            width: 90px;
            height: calc(100% - 4px);
            position: absolute;
            right: 2px;
            top: 2px;
            background-color: rgb(233, 7, 7);
            color: #fff;
            font-weight: 600;
            font-size: 16px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .textBox {
          margin-top: 10px;
          overflow: hidden;
          padding-left: 5px;
          .txt {
            float: left;
            margin-left: 15px;
            cursor: pointer;
            font-size: 12px;
          }
          .txt:hover {
            color: #f66;
          }
        }
      }
    }
    .contBox {
      margin: 10px;
      padding: 15px;
      background-color: #fff;
      border-radius: 10px;
      display: flex;
      position: relative;
      .tcBox {
        position: absolute;
        top: 15px;
        left: 240px;
        border: 2px solid #f40;
        z-index: 99;
        border-radius: 10px;
        padding: 20px;
        width: 800px;
        box-sizing: border-box;
        background-color: #fff;
        padding-bottom: 5px;
        height: 445px;
        overflow: hidden;
        .nameBox {
          display: flex;
          float: left;
          width: 90px;
          align-items: center;
          .name {
            font-weight: 600;
          }
          .iconfont {
            font-size: 12px;
            color: #9c9c9c;
            margin-left: 4px;
          }
        }
        .list {
          float: left;
          // padding-left: 20px;
          box-sizing: border-box;
          width: calc(100% - 340px);
          margin-right: 20px;
          overflow: auto;
          height: 100%;
          .name {
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 15px;
          }
          .li {
            margin-right: 20px;
            margin-bottom: 15px;
            cursor: pointer;
            // float: left;
          }
        }
        .tjCpBox {
          width: 320px;
          float: left;
          .name {
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 15px;
          }
          .tjList {
            margin-right: 15px;
            margin-bottom: 10px;
            overflow: hidden;
            .tjLi {
              float: left;
              width: calc(50% - 7.5px);
              margin-right: 15px;
              .img {
                width: 100%;
                padding-top: 100%;
                position: relative;
                border-radius: 5px;
                img {
                  position: absolute;
                  top: 0;
                  left: 0;
                  border-radius: 5px;
                }
              }
              .text {
                text-align: center;
                line-height: 40px;
              }
            }
            .tjLi:nth-child(2n) {
              margin-right: 0;
            }
          }
        }
      }
      .leftBox {
        float: left;
        padding: 15px;
        background-color: whitesmoke;
        border-radius: 10px;
        width: 270px;
        .title {
          font-weight: 600;
          font-size: 16px;
          margin-bottom: 10px;
          height: 30px;
        }
        .list {
          color: #666;
          height: 240px;
          .li {
            margin-bottom: 5px;
            cursor: pointer;
            height: 25px;
          }
          .li:last-child {
            margin-bottom: 0;
          }
        }
      }
      .lbBox {
        box-sizing: border-box;
        padding-left: 15px;
        float: left;
        width: 560px;
        .imgs {
          border-radius: 10px;
          overflow: hidden;
          height: 360px;
          vertical-align: middle;
          overflow: hidden;
          img {
            object-fit: cover;
          }
        }
      }
      .funBox {
        width: 280px;
        height: 360px;
        padding-left: 15px;
        box-sizing: border-box;
        .cont {
          // width: calc(50% - 7.5px);
          float: left;
          // margin-right: 15px;
          width: 100%;
          height: 172.5px;
          margin-bottom: 15px;
          background-color: rgb(255, 241, 235);
          border-radius: 10px;
          padding: 10px 15px;
          box-sizing: border-box;
          .titleBox {
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            height: 25px;
            .name {
              font-weight: 600;
              font-size: 16px;
              color: #11192d;
            }
            .enter {
              font-size: 12px;
              color: #9c9c9c;
            }
          }
          .list {
            padding-top: 5px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: calc(100% - 25px);
            .li {
              cursor: pointer;
            }
            .li:hover {
              color: #f40;
            }
            .cpImgBox {
              width: calc(50% - 5px);
              height: 100%;
              border: 1px solid #ebebeb;
              box-sizing: border-box;
              margin-right: 10px;
            }
            .cpImgBox:last-child {
              margin-right: 0;
            }
          }
          .cpImgBox {
            padding-top: 5px;
            height: calc(100% - 25px);
            overflow: hidden;
            .cpImg {
              width: calc(50% - 7.5px);
              cursor: pointer;
              float: left;
              height: 100%;
              // border: 1px solid #ebebeb;
              box-sizing: border-box;
              background-color: #fff;
              border-radius: 10px;
              margin-right: 15px;
              overflow: hidden;
              position: relative;
              img {
                object-fit: cover;
              }
              .box {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.1);
                .txt {
                  color: #fff;
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  height: 40px;
                  line-height: 40px;
                  font-size: 12px;
                  padding-left: 10px;
                }
              }
            }
            .cpImg:last-child {
              margin-right: 0;
            }
          }
        }
        .cont:nth-child(2n) {
          margin-right: 0;
          background-color: rgb(255, 235, 235);
        }
        .mb0 {
          margin-bottom: 0;
        }
      }
      .userBox {
        width: calc(100% - 1120px);
        margin-left: 10px;
        .logCont {
          display: flex;
          flex-direction: column;
          align-items: center;
          .txImg {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            overflow: hidden;
            img {
              object-fit: cover;
            }
          }
          .timeTxt {
            font-weight: 700;
            height: 45px;
            line-height: 45px;
            font-size: 18px;
          }
          .dlBox {
            height: 45px;
            width: 100%;
            margin-bottom: 10px;
            .box {
              width: calc(50% - 5px);
              margin-right: 10px;
              float: left;
              color: #fff;
              font-weight: 600;
              font-size: 16px;
              background-color: #ff6408;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 10px;
              cursor: pointer;
            }
            .box:last-child {
              margin-right: 0;
              background-color: #feb306;
            }
          }
          .jlBox {
            height: 55px;
            width: 100%;
            overflow: hidden;
            border-radius: 10px;
            margin-bottom: 10px;
            margin-top: 5px;
            .jlBoxCont {
              height: 55px;
              width: 100%;
              overflow: hidden;
              border-radius: 10px;
              background-color: whitesmoke;
              padding: 5px;
              box-sizing: border-box;
              overflow: hidden;
              .img {
                float: left;
                width: 45px;
                height: 100%;
                img {
                  object-fit: cover;
                }
              }
              .box {
                float: left;
                padding-left: 10px;
                width: calc(100% - 100px);
                box-sizing: border-box;
                height: 45px;
                display: flex;
                flex-direction: column;
                font-size: 12px;
                justify-content: space-between;
                .name {
                  color: #11192d;
                }
                .txt {
                  color: #999;
                }
              }
              .btn {
                width: 45px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                color: #11192d;
                margin-top: 8px;
                margin-left: 5px;
                background-color: #fff;
                border-radius: 5px;
                float: left;
                cursor: pointer;
              }
            }
          }
          .cxBox {
            height: 130px;
            width: 100%;
            padding: 0 10px;
            padding-top: 10px;
            box-sizing: border-box;
            .box {
              width: calc(33.33% - 10px);
              margin-right: 15px;
              float: left;
              cursor: pointer;
              position: relative;
              .cxImg {
                width: 45px;
                height: 45px;
                border-radius: 10px;
                box-sizing: border-box;
                overflow: hidden;
                margin: auto;
              }
              .txt {
                font-size: 12px;
                height: 35px;
                line-height: 35px;
                text-align: center;
              }
              .ewmBox {
                position: absolute;
                top: 65px;
                left: -10px;
                background-color: #ebebeb;
                border-radius: 10px;
                z-index: 9;
                height: 80px;
                width: 80px;
                .icon {
                  width: 100%;
                  text-align: center;
                  position: absolute;
                  top: -20px;
                  left: 0;
                  .play_fill {
                    transform: rotate(-90deg);
                    font-size: 24px;
                    color: #ebebeb;
                  }
                }
                .img {
                  margin: 5px;
                  border-radius: 10px;
                  overflow: hidden;
                  box-sizing: border-box;
                }
              }
            }
            .box:hover {
              .txt {
                color: #f40;
              }
            }
            .box:nth-child(3n) {
              margin-right: 0;
            }
          }
        }
      }
    }
    .contentTitle {
      height: 60px;
      font-size: 26px;
      margin-top: 30px;
      // margin-bottom: 10px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 70px;
      .hen {
        flex: 1;
        height: 3px;
        background: linear-gradient(
          to right,
          rgba(233, 0, 0, 0),
          rgba(233, 0, 0, 0.7)
        );
        border-radius: 3px;
      }
      .hen1 {
        flex: 1;
        height: 3px;
        border-radius: 3px;
        background: linear-gradient(
          to left,
          rgba(233, 0, 0, 0),
          rgba(233, 0, 0, 0.7)
        );
      }
      .name {
        margin: 0 30px;
        position: relative;
        top: -4px;
        color: #333;
      }
    }
    .typeList {
      height: 70px;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      .type {
        padding: 10px 20px;
        border-radius: 5px;
      }
      .on {
        // background-color: rgb(255, 234, 234);
        // background-color: #fff;
        color: #72aef3;
      }
    }
    .selectBox {
      background-color: #fff;
      border-radius: 10px;
      margin: 0 10px;
      margin-bottom: 15px;
      margin-top: 20px;
      .titleBox {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        .name {
          font-weight: 600;
          font-size: 20px;
        }
        .inpBox {
          height: 35px;
          border-radius: 5px;
          border: 1px solid #f40;
          width: 250px;
          overflow: hidden;
          display: flex;
          align-items: center;
          input {
            width: calc(100% - 30px);
            height: 100%;
            box-sizing: border-box;
            padding-left: 10px;
          }
          .search {
            font-size: 20px;
            color: #9c9c9c;
            cursor: pointer;
          }
        }
      }
      .pzBox {
        border-top: 1px solid #ebebeb;
        height: 61px;
        overflow: hidden;
        padding: 20px 20px 5px;
        position: relative;
        .pzCnt {
          display: flex;
          .name {
            font-weight: 600;
            width: 120px;
          }
          .boxs {
            width: calc(100% - 120px);
            overflow: hidden;
            .box {
              float: left;
              margin-right: 25px;
              margin-bottom: 15px;
              color: #666;
              cursor: pointer;
            }
            .on {
              color: #0976f3;
            }
          }
        }
        .pzCnt:nth-child(2n) {
          width: calc(100% - 100px);
        }
      }
      .pzBoxh {
        height: auto;
      }
    }
    .contList {
      overflow: hidden;
      padding: 0 10px;
      .cont {
        cursor: pointer;
        width: calc(20% - 12px);
        margin-right: 15px;
        background-color: #fff;
        border-radius: 10px;
        overflow: hidden;
        float: left;
        height: 369px;
        margin-bottom: 15px;
        position: relative;
        .bjImg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          img {
            object-fit: cover;
          }
        }
        .bjBox {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          .contTxt {
            height: 50px;
            margin-top: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            .title1 {
              padding: 10px 25px;
              border-radius: 30px;
              color: #fff;
              background-color: rgb(240, 12, 12);
              font-size: 16px;
              font-weight: 600;
            }
          }
          .bjList {
            padding: 0 25px;
            margin-top: 20px;
            .bjLi {
              border: 1px solid rgb(204, 58, 5);
              color: rgb(226, 20, 13);
              border-radius: 10px;
              margin-right: 10px;
              margin-bottom: 10px;
              width: calc(50% - 5px);
              float: left;
              height: 35px;
              line-height: 35px;
              text-align: center;
            }
            .bjLi:nth-child(2n) {
              margin-right: 0;
            }
          }
        }
        .cpImg {
          height: 280px;
          border-radius: 10px;
          overflow: hidden;
          img {
            object-fit: cover;
          }
        }
        .title {
          height: 40px;
          box-sizing: border-box;
          padding: 10px;
          font-size: 16px;
          padding-bottom: 0;
        }
        .money {
          display: flex;
          padding: 0 10px;
          padding-bottom: 10px;
          align-items: flex-end;
          .txt {
            margin-bottom: 5px;
            color: #f40;
          }
          .num {
            font-weight: 600;
            font-size: 26px;
            color: #f40;
          }
          .text {
            margin-bottom: 5px;
            margin-left: 10px;
            color: #9c9c9c;
          }
        }
      }
      .cont:nth-child(5n) {
        margin-right: 0;
      }
    }
  }
}
</style>
